div.col-4 {
  -webkit-column-width: 15em;
  -moz-column-width: 15em;
  column-width: 15em;
  -webkit-column-count: 4;
  -moz-column-count: 4;
  column-count: 4;
  -webkit-column-gap: 1em;
  -moz-column-gap: 1em;
  column-gap: 1em;
  -webkit-column-rule: 1px dotted #ddd;
  -moz-column-rule: 1px dotted #ddd;
  column-rule: 1px dotted #ddd; }

i.fa-colour-beige {
  color: beige; }

i.fa-colour-black {
  color: black; }

i.fa-colour-blue {
  color: blue; }

i.fa-colour-brown {
  color: brown; }

i.fa-colour-cyan {
  color: cyan; }

i.fa-colour-fuchsia {
  color: fuchsia; }

i.fa-colour-gold {
  color: gold; }

i.fa-colour-gray {
  color: gray; }

i.fa-colour-green, i.enabled {
  color: green; }

i.fa-colour-magenta {
  color: magenta; }

i.fa-colour-navy {
  color: navy; }

i.fa-colour-olive {
  color: olive; }

i.fa-colour-orange, i.disabled {
  color: orange; }

i.fa-colour-pink {
  color: pink; }

i.fa-colour-purple {
  color: purple; }

i.fa-colour-red, i.deletable {
  color: red; }

i.fa-colour-silver {
  color: silver; }

i.fa-colour-teal {
  color: teal; }

i.fa-colour-violet {
  color: violet; }

i.fa-colour-white {
  color: white; }

i.fa-colour-yellow {
  color: yellow; }

.disable_a_href {
  pointer-events: none; }

.sidebar-mini.sidebar-collapse .main-header .logo > .logo-mini {
  font-size: 18px !important; }

.bootstrap-tagsinput {
  width: 100%; }

/*# sourceMappingURL=app.css.map */
